<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div
                            class="grow text-xs-center"
                            :style="[eventSearch ? 'opacity: 0' : 'opacity: 1']"
                        >
                            SỬA KẾ HOẠCH
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                v-if="!eventSearch"
                                type="default"
                                icon="mdi mdi-magnify "
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="row mt-2 mb-3">
                <div class="xs12">
                    <div class="style-title">
                        <div class="mr-1">
                            <i class="mdi mdi-road-variant"></i>
                        </div>
                        <span class="font-medium">
                            {{ Get_TenTuyen || "" }}
                        </span>
                    </div>
                    <div class="d-flex-c">
                        <div class="style-title mr-2">
                            <div class="mr-1">
                                <i class="mdi mdi-calendar"></i>
                            </div>
                            <span class="font-medium">
                                {{
                                    Get_ChiTietKeHoach.Not
                                        ? Get_ChiTietKeHoach.Not.substr(0, 5)
                                        : ""
                                }}</span
                            >
                        </div>
                        <div class="style-title">
                            <div class="mr-1">
                                <i class="mdi mdi-bus-stop"></i>
                            </div>
                            <span class="font-medium">
                                {{
                                    Get_TenTuyen != null ? Get_TenTuyen.split("-")[0] : ""
                                }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <DxValidationGroup ref="formValidation" style="padding-bottom: 50px">
                <div class="row pb-3 px-3">
                    <div class="xs12">
                        <span class="font-12">Biển kiểm soát (*)</span>
                        <DxSelectBox
                            :data-source="DSXeDuKien"
                            value-expr="IdDnvtXe"
                            display-expr="BienKiemSoat"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            :onValueChanged="onChangeTrangThai"
                            field-template="field"
                            v-model:value="Get_BienKS.IdDnvtXe"
                            :search-enabled="true"
                            :showClearButton="true"
                        >
                            <template #field="{ data }">
                                <DxTextBox
                                    :value="
                                        data
                                            ? data.BienKiemSoat +
                                              ` (${$Helper.formatDate(
                                                  data.PhuHieu_NgayHetHan,
                                              )})`
                                            : ``
                                    "
                                    :class="
                                        data &&
                                        new Date(data.PhuHieu_NgayHetHan) <
                                            $Helper.getEndDay(new Date())
                                            ? 'color-warning-han-phu-hieu'
                                            : ''
                                    "
                                    hint="Là hạn phù hiệu trên tuyến của xe"
                                />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Trạng thái không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row pb-3 px-3">
                    <div class="xs12">
                        <span class="font-12">Lái xe tiếp nhận lệnh (*)</span>
                        <DxSelectBox
                            :data-source="DSLaiXeDuKien"
                            value-expr="IdDnvtLaiXe"
                            v-model="Get_LaiXeTiepNhanLenh.IdDnvtLaiXe"
                            display-expr="HoTen"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            :onItemClick="onChangeLaiXeTiepNhanLenh"
                            :search-enabled="true"
                            :showClearButton="true"
                        >
                            <DxValidator ref="validateLaiXeTiepNhanLenh">
                                <DxRequiredRule
                                    message="Lái xe tiếp nhận lệnh không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row pb-3 px-3">
                    <div class="xs12">
                        <span class="font-12">Lái xe đi cùng</span>
                        <DxTagBox
                            :data-source="DanhSachLaiXePhu"
                            display-expr="HoTen"
                            value-expr="IdDnvtLaiXe"
                            :show-selection-controls="true"
                            :multiline="false"
                            :show-multi-tag-only="true"
                            :search-enabled="true"
                            :showDropDownButton="true"
                            v-model:value="LaiXePhu"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :onValueChanged="onValueChangedLaiXe"
                            :showClearButton="true"
                        >
                        </DxTagBox>
                    </div>
                </div>
                <div class="row pb-3 px-3">
                    <div class="xs12">
                        <span class="font-12">Phụ xe</span>
                        <DxTextBox
                            validationMessageMode="always"
                            styling-mode="underlined"
                            :onValueChanged="onChangeTrangThai"
                            v-model="phuXe"
                            :showClearButton="true"
                        >
                            <DxValidator>
                                <DxStringLengthRule
                                    :max="48"
                                    message="Phụ xe không vượt quá 48 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>
                <div class="row p-fixed">
                    <div class="xs12">
                        <div
                            class="row justify-space-between"
                            style="
                                border-top: 1px solid #dadce0;
                                padding: 0 10px;
                                height: 50px;
                                align-items: center;
                            "
                        >
                            <div class="text-xs-center">
                                <DxButton
                                    text="Hủy"
                                    type="danger"
                                    styling-mode="text"
                                    width="100%"
                                    class="color-danger"
                                    @click="$router.go(-1)"
                                />
                            </div>
                            <div class="text-xs-center">
                                <DxButton
                                    text="Xác nhận"
                                    type="default"
                                    styling-mode="contained"
                                    class="color-primary"
                                    @click="CapNhatKeHoach()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DxValidationGroup>
        </ion-content>
    </ion-page>
</template>
<script>
import {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    onIonViewWillEnter,
} from "@ionic/vue";
import {
    DxButton,
    DxTextBox,
    DxSelectBox,
    DxValidationGroup,
    DxValidator,
    DxDateBox,
    DxTagBox,
} from "devextreme-vue";
import { DxStringLengthRule } from "devextreme-vue/validator";
import { DxRequiredRule, DxCustomRule } from "devextreme-vue/data-grid";
import DxList from "devextreme-vue/list";
import PopupVue from "../../../components/_Common/Popup.vue";
export default {
    components: {
        DxButton,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
        DxTextBox,
        DxSelectBox,
        DxRequiredRule,
        DxStringLengthRule,
        DxCustomRule,
        DxValidationGroup,
        DxValidator,
        DxDateBox,
        DxList,
        DxTagBox,
        PopupVue,
        onIonViewWillEnter,
    },
    data() {
        return {
            TuNgay: this.$Helper.getStartDay(new Date()),
            DenNgay: this.$Helper.getStartDay(new Date()),
            chiTietKeHoach: [],
            DSLaiXeDuKien: [],
            DanhSachLaiXePhu: [],
            DSXeDuKien: [],
            dialogLoc: false,
            selectedItemKeys: [],
            maxItems: 2,
            LaiXePhu: [],
            phuXe: "",
            Get_LaiXeTiepNhanLenh: {
                IdDnvtLaiXe: null,
            },
        };
    },
    computed: {
        Get_ChiTietKeHoach() {
            return this.$store.state.DVVTLenh.ChiTietKeHoach || {};
        },
        Get_DanhSachLaiXeDuKien() {
            return this.$store.state.DVVTLenh.danhSachLaiXe || [];
        },
        Get_LaiXe() {
            return this.$store.state.DVVTLenh.laiXeTiepNhanLenh || [];
        },
        DanhSachLaiXeDiCung() {
            let arrTemp = [];
            for (let i = 1; i < this.Get_LaiXe.length; i++) {
                arrTemp.push(this.Get_LaiXe[i].IdDnvtLaiXe);
            }
            return arrTemp;
        },
        Get_BienKS() {
            return this.$store.state.DVVTLenh.bienKiemSoat || {};
        },
        Get_HoTenPhuXe() {
            return this.$store.state.DVVTLenh.hoTenPhuXe || "";
        },
        Get_TenTuyen() {
            return this.$store.state.DVVTLenh.TenTuyen;
        },
    },
    watch: {
        Get_LaiXeTiepNhanLenh: {
            immediate: true,
            deep: true,
            handler: function () {
                this.LaiXePhu = this.LaiXePhu.filter(
                    (e) => e != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                this.DanhSachLaiXePhu = this.DSLaiXeDuKien.filter(
                    (e) => e.IdDnvtLaiXe != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                this.LaiXePhu = this.LaiXePhu.filter((lx) =>
                    this.DanhSachLaiXePhu.some((e) => lx == e.IdDnvtLaiXe),
                );
            },
        },
        TuNgay() {
            setTimeout(() => {
                this.GetChiTietKeHoach();
            }, 200);
        },
        DenNgay() {
            setTimeout(() => {
                this.GetChiTietKeHoach();
            }, 200);
        },
    },
    methods: {
        toastThongBao(state, msg) {
            let ParamsError = {
                visible: true,
                message: msg,
                type: state,
            };
            this.emitter.emit("onToast", ParamsError);
        },
        onChangeLaiXeTiepNhanLenh(e) {
            if (e.itemData) {
                this.LaiXePhu = this.LaiXePhu.filter(
                    (e) => e != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                this.DanhSachLaiXePhu = this.DSLaiXeDuKien.filter(
                    (e) => e.IdDnvtLaiXe != this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
            }
        },
        onValueChangedLaiXe(e) {
            if (e.value?.length > this.maxItems) {
                const newValue = e.value.slice(0, this.maxItems);
                e.component.option("value", newValue);
            }
        },
        async GetDanhSachXeDuKien() {
            try {
                // let key = "ID_KeHoach";
                // let query = {
                //     TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                //     DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                //     IdDnvtTuyen: this.filter.TuyenVanChuyen,
                // };
                let IdKeHoach = this.$route.query.IdKeHoach || "";
                this.DSXeDuKien = await this.$store.dispatch(
                    "DVVTLenh/Get_DanhSachXeDuKienTheoKeHoach",
                    {
                        IdKeHoach: IdKeHoach,
                    },
                );
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },

        async GetDanhSachLaiXeDuKien() {
            try {
                // let key = "ID_KeHoach";
                // let query = {
                //     TuNgay: this.$Helper.getStartDay(this.filter.TuNgay),
                //     DenNgay: this.$Helper.getStartDay(this.filter.DenNgay),
                //     IdDnvtTuyen: this.filter.TuyenVanChuyen,
                // };
                let IdKeHoach = this.$route.query.IdKeHoach || "";
                this.DSLaiXeDuKien = await this.$store.dispatch(
                    "DVVTLenh/Get_DanhSachLaiXeDuKienTheoKeHoach",
                    {
                        IdKeHoach: IdKeHoach,
                    },
                );

                this.Get_LaiXeTiepNhanLenh = {};

                let itemLaiXeTiepNhanLenh =
                    this.Get_LaiXe.length && this.Get_LaiXe.length > 0
                        ? this.Get_LaiXe[0]
                        : {};

                if (!itemLaiXeTiepNhanLenh.IdDnvtLaiXe) {
                    return;
                }
                
                let check = this.DSLaiXeDuKien.some(
                    (e) => e.IdDnvtLaiXe == itemLaiXeTiepNhanLenh.IdDnvtLaiXe,
                );
                if (!check) {
                    this.$Helper.ThongBaoToast(
                        "warning",
                        "Không tìm thấy thông tin lái xe tiếp nhận lệnh theo kế hoạch!",
                    );
                }
                this.Get_LaiXeTiepNhanLenh = check ? itemLaiXeTiepNhanLenh : {};
            } catch (ex) {
                console.log("🚀 ex", ex);
            } finally {
                if (!this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe) {
                    let resetLaiXeTiepNhanLenh = setInterval(() => {
                        if (this.$refs.validateLaiXeTiepNhanLenh) {
                            this.$refs.validateLaiXeTiepNhanLenh.instance.reset();
                            clearInterval(resetLaiXeTiepNhanLenh);
                        }
                    }, 100);
                }
            }
        },
        async GetChiTietKeHoach(changeKeHoach) {
            this.$startLoading;
            try {
                let query = {
                    TuNgay: this.TuNgay,
                    DenNgay: this.DenNgay,
                    IdKeHoach: this.$route.query.IdKeHoach,
                    changeKeHoach: changeKeHoach,
                };

                let result = await this.$store.dispatch("DVVTLenh/ChiTietKeHoach", {
                    query,
                });
                this.chiTietKeHoach = result;
                this.$stopLoading;
            } catch (ex) {
                this.$stopLoading;
                console.log("🚀 ex", ex);
            }
        },
        async CapNhatKeHoach() {
            let ParamsError = {
                isVisible: true,
                message: "",
                type: "error",
            };
            let isCheck = this.$refs.formValidation.instance.validate();
            if (!isCheck.isValid) {
                return;
            }
            try {
                let query = {
                    HoTenPhuXe: this.phuXe,
                    IdDnvtLaiXes: [
                        this.Get_LaiXeTiepNhanLenh.IdDnvtLaiXe,
                        ...this.LaiXePhu,
                    ],
                    IdDnvtXe: this.Get_BienKS.IdDnvtXe,
                    IdKeHoach: this.$route.query.IdKeHoach,
                };

                let result = await this.$store.dispatch("DVVTLenh/CapNhatKeHoach", query);
                if (result.errorCode == 0 && result.status == true) {
                    ParamsError.type = "success";
                    ParamsError.message = "Lưu thông tin kế hoạch thành công!";
                    this.emitter.emit("onToast", ParamsError);
                    this.toastThongBao("success", ParamsError.message);
                    this.$router.push("/DNVT-Quan-Ly-Lenh");
                } else {
                    ParamsError.message = result.message;
                    this.emitter.emit("onToast", ParamsError);
                    this.toastThongBao("error", ParamsError.message);
                }
            } catch (err) {
                console.log(err);
            }
        },
    },

    created() {
        onIonViewWillEnter(() => {
            this.$startLoading;
            this.GetDanhSachXeDuKien();
            this.GetDanhSachLaiXeDuKien();
            this.GetChiTietKeHoach(true);
            this.LaiXePhu = this.DanhSachLaiXeDiCung;
            this.phuXe = this.Get_HoTenPhuXe;
            this.$stopLoading;
        });
    },
    mounted() {},
};
</script>
<style lang="css">
.style-title {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
}
.p-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
}

.d-flex-c {
    display: flex;
    justify-content: center;
}
</style>
